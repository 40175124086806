import { styled, css } from "styled-components";

import Button from "components/Button";
import { default as NavLink } from "components/Link";
import {
    styleBodyM,
    styleHeadingM,
    styleHeadingS,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Wrapper = styled.nav`
    margin-bottom: 2.625rem;

    ${MQ.FROM_M} {
        margin-bottom: 0;
    }

    ${MQ.FROM_XL} {
        margin-top: 4.8125rem;
    }
`;

export const NavItems = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 0.4375rem;
    padding: 0;
    row-gap: 0.4375rem;

    &:last-child {
        margin-bottom: 0;

        & li:last-child {
            margin-bottom: 0;
        }
    }

    ${MQ.FROM_XL} {
        margin-bottom: 0;
        row-gap: 0.875rem;
    }
`;

export const NavItem = styled.li.attrs<{
    $hasChildren?: boolean;
}>(() => ({}))`
    list-style: none;

    &:last-child {
        ul {
            li:last-child {
                ${MQ.FROM_XL} {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export const NavItemsWrapper = styled.div.attrs<{
    $itemsPerColumn?: number;
}>(() => ({}))`
    ${columnGap};
    flex-wrap: wrap;

    ${MQ.FROM_XL} {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, [col-start] 1fr);
    }
`;

export const LinkWrapper = styled.div.attrs<{
    $open?: boolean;
}>(() => ({}))`
    align-items: center;
    display: flex;
    gap: 0.875rem;
    justify-content: space-between;
    position: relative;
    transition: margin-bottom 0.2s ease-in-out;
`;

const linkStyle = css`
    color: var(--text-on-neutral-primary);
    padding: 0;

    span {
        border-bottom-color: var(--color-black-o00);
    }

    &:hover {
        span {
            border-bottom: 1px solid var(--text-on-neutral-primary);
        }
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    &.active {
        span {
            border-bottom: 1px solid var(--text-on-neutral-link-alt-border);
        }

        &:hover {
            span {
                border-bottom: 1px solid var(--text-on-neutral-primary);
            }
        }
    }
`;

export const LinkLvl2 = styled(NavLink)`
    ${styleHeadingM};
    ${linkStyle}
    padding: 0.4375rem 0 0.375rem;

    ${MQ.FROM_XL} {
        ${styleHeadingS};
        ${linkStyle}
    }
`;

export const LinkLvl3 = styled(NavLink)`
    ${styleBodyM}
    ${linkStyle}
`;

export const Expand = styled(Button)`
    && {
        align-self: flex-start;
        border: 1px solid var(--button-on-neutral-outline-border);
        border-radius: 0.25rem;
        height: 2.625rem;
        padding: 0.375rem;
        right: 0;
        top: 0;

        @media (hover: hover) {
            &:hover:not(.disabled) {
                background-color: var(--button-on-blue-primary);
                border-color: var(--button-on-blue-primary);
                color: var(--text-on-cyan-primary);
            }
        }
    }
`;

export const InnerNav = styled.ul.attrs<{
    $open?: boolean;
}>(() => ({}))`
    display: flex;
    flex-flow: column;
    margin-top: 0.4375rem;
    ${(props) =>
        props.$open
            ? css`
                  margin-top: 0.4375rem;
                  max-height: 1000vh;
              `
            : css`
                  margin-top: 0;
                  max-height: 0;
              `}
    overflow: hidden;
    padding-left: 1.75rem;
    padding-right: 2.625rem;
    row-gap: 0.4375rem;
    transition:
        margin-top 0.2s ease-in-out,
        max-height 0.2s ease-in-out;

    ${NavItem} {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0.875rem;
        }

        ${MQ.FROM_XL} {
            margin-bottom: 0;
        }
    }
`;
